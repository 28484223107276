import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserServices from "../../services/UserServices";
import { CenterRippleLoading } from "../loading/CenterRippleLoading";

const UserProfile = () => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("loggedUser")!;
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [curriculum, setCurriculum] = useState("");

  useEffect(() => {
    UserServices.FetchUserProfileData(userToken).then((response) => {
      setFirstName(response.first_name);
      setLastName(response.last_name);
      setCurriculum(response.curriculum);
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = async () => {
    setIsSaving(true);
    const dataPromise = UserServices.PatchUserProfileData(
      curriculum,
      firstName,
      lastName,
      userToken
    );
    toast.promise(
      dataPromise,
      {
        pending: "Saving changes...",
        success: "Changes saved!",
        error: "Error saving changes",
      },
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
    setIsSaving(false);
  };

  if (isLoading) return CenterRippleLoading("Loading your profile...");

  return (
    <div>
      <input
        type="button"
        onClick={() => {
          navigate("/");
          navigate(0);
        }}
        className="btn btn-primary"
        style={{ marginBottom: "1rem" }}
        value="Back"
      />

      <div className="mb-3">
        <label htmlFor="first-name" className="form-label">
          First Name:
        </label>
        <input
          type="text"
          id="first-name"
          className="form-control"
          placeholder="John"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="last-name" className="form-label">
          Last Name:
        </label>
        <input
          type="text"
          id="last-name"
          className="form-control"
          placeholder="Doe"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="curriculum" className="form-label">
          Curriculum:
        </label>
        <textarea
          className="form-control"
          id="curriculum"
          value={curriculum}
          style={{ minHeight: "140px" }}
          onChange={(e) => setCurriculum(e.target.value)}
        />
      </div>

      <input
        type="button"
        value="Save changes"
        disabled={isSaving}
        onClick={() => handleSubmit()}
        className="btn btn-primary"
        style={{ marginBottom: "1rem" }}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        transition={Slide}
      />
    </div>
  );
};

export default UserProfile;
