import React from "react";
import "./RippleLoading.css";

class RippleLoading extends React.Component {
  render() {
    return (
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    );
  }
}

export default RippleLoading;
