import { AxiosError } from "axios";
import { LoggedUser } from "../types/LoggedUser";
import BaseApi from "./HttpServices";

class AuthService extends BaseApi {
  async login(username: string, password: string): Promise<string> {
    try {
      const response = await this.api.post("/auth/login", {
        username,
        password,
      });
      return response.data.data.access_token;
    } catch (error) {
      const response = (error as AxiosError).response;
      if (response?.status === 401) {
        throw new Error("Invalid username or password");
      }
      console.error("Login failed", response?.data);
      throw error;
    }
  }

  async isLogged(token: string): Promise<LoggedUser> {
    try {
      const response = await this.api.get("/user/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error checking verification", error);
      throw error;
    }
  }
}

export default new AuthService();
