import BaseApi from "./HttpServices";

type UserProfile = {
  curriculum: string;
  first_name: string;
  last_name: string;
};

class UserService extends BaseApi {
  async FetchUserProfileData(token: string): Promise<UserProfile> {
    try {
      const response = await this.api.get("/user/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching user data", error); // TODO fix error messages
      throw error;
    }
  }

  async PatchUserProfileData(
    curriculum: string,
    firstName: string,
    lastName: string,
    token: string
  ): Promise<Boolean> {
    try {
      const response = await this.api.patch(
        "/user/profile",
        {
          curriculum,
          first_name: firstName,
          last_name: lastName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return true;
    } catch (error) {
      console.error("Error updating the user profile", error);
      throw error;
    }
  }
}
const userService = new UserService();
export default userService;
