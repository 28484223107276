import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import AuthServices from "../../services/AuthServices";
import RippleLoading from "../loading/RippleLoading";

type LoginFormProps = {
  submitCallback: (username: string, password: string) => Promise<void>;
};

const LoginForm = (props: LoginFormProps) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "username":
        setUsername(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={() => props.submitCallback(username, password)}>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">
            Username
          </span>
        </div>
        <input
          name="username"
          type="text"
          value={username}
          onChange={handleInputChange}
          className="form-control"
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">
            Password&nbsp;
          </span>
        </div>
        <input
          name="password"
          type="password"
          value={password}
          onChange={handleInputChange}
          className="form-control"
          placeholder="Password"
          aria-label="Password"
          aria-describedby="basic-addon1"
        />
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <input type="submit" value="Submit" className="btn btn-primary" />
      </div>
    </form>
  );
};

const Login = () => {
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const key = "loggedUser";
    const token = localStorage.getItem(key);
    if (token) {
      AuthServices.isLogged(token)
        .then(() => {
          navigate("/");
        })
        .catch(() => {
          localStorage.removeItem(key);
        });
    }
  }, [navigate]);

  const handleSubmit = async (
    username: string,
    password: string
  ): Promise<void> => {
    setIsWorking(true);
    try {
      const token = await AuthServices.login(username, password);
      if (token) {
        const key = "loggedUser";
        localStorage.setItem(key, token);
        navigate("/");
      } else {
        navigate("/login");
      }
    } catch (error) {
      setIsWorking(false);
      toast.error(
        "Login failed. Please check your username and password and try again.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
  };

  return (
    <div
      style={{
        flexFlow: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h3>Login</h3>
      <br />
      {isWorking ? (
        <RippleLoading />
      ) : (
        <LoginForm {...{ submitCallback: handleSubmit }} />
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        transition={Slide}
      />
    </div>
  );
};

export default Login;
