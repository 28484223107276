import RippleLoading from "./RippleLoading";

export const CenterRippleLoading = (text: string) => {
  return (
    <div
      style={{
        flexFlow: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>{text}</h1>
      <RippleLoading />
    </div>
  );
};
