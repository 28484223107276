import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InterviewService } from "../../services/InterviewServices";
import RippleLoading from "../loading/RippleLoading";

const InterviewLobby = () => {
  const navigate = useNavigate();

  const [role, setRole] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const handleSubmit = () => {
    setIsWorking(true);
    if (role.length < 7) {
      alert("Role name must be at least 7 characters long");
      setIsWorking(false);
      return;
    }
    if (jobDescription.length < 300) {
      alert("Job description must be at least 300 characters long");
      setIsWorking(false);
      return;
    }
    const userToken = localStorage.getItem("loggedUser");
    const interviewService = new InterviewService({ token: userToken!! });
    interviewService
      .CreateInterview(role, jobDescription)
      .then((data) => {
        console.log("Interview created");
        navigate(`/interview/${data.interview_id}`);
      })
      .catch((error) => {
        console.error("Error creating interview", error);
        setIsWorking(false);
      });
  };

  return isWorking ? (
    <div
      style={{
        flexFlow: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h4>Creating interview...</h4>
      <RippleLoading />
    </div>
  ) : (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => navigate("/")}
      >
        Back
      </button>
      <br />
      <br />
      <br />
      <div className="mb-3">
        <label htmlFor="role-name" className="form-label">
          Role name/ Job Position
        </label>
        <input
          type="text"
          id="role-name"
          className="form-control"
          placeholder="Software Engineer"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        />
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="job-description" className="form-label">
          Role name/ Job Position
        </label>
        <textarea
          className="form-control"
          id="job-description"
          value={jobDescription}
          style={{ minHeight: "140px" }}
          onChange={(e) => setJobDescription(e.target.value)}
        />
      </div>
      <br />
      <br />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => handleSubmit()}
      >
        Create Interview
      </button>
    </div>
  );
};

export default InterviewLobby;
