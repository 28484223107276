import Avatar1 from "../../assets/avatars/avatar-1.gif";
import Avatar2 from "../../assets/avatars/avatar-2.gif";
import Avatar3 from "../../assets/avatars/avatar-3.gif";
import Avatar4 from "../../assets/avatars/avatar-4.gif";
import BotAvatar1 from "../../assets/avatars/chatbot.gif";
import WorkingIcon from "../../assets/icons/working.gif";

export const AvatarList = [Avatar1, Avatar2, Avatar3, Avatar4];
export const BotAvatarList = [BotAvatar1];
type IconListType = {
  working: string;
  [key: string]: string;
};
const IconList: IconListType = {
  working: WorkingIcon,
};

const stopAnim = (img: any, id: string) => {
  const coverImage = function () {
    setTimeout(function () {
      const canvas = document.createElement("canvas");
      const parent = img.parentNode;
      const width = parent.offsetWidth;
      const height = parent.offsetHeight;
      canvas.width = width;
      canvas.height = height;
      canvas.setAttribute("id", id);
      canvas.getContext("2d")!.drawImage(img, 0, 0, width, height);
      canvas.style.position = "absolute";
      canvas.setAttribute("aria-hidden", "true");
      canvas.setAttribute("role", "presentation");
      parent.insertBefore(canvas, img);
      img.style.opacity = 0.01;
    }, 0);
  };

  if (img.complete) {
    coverImage();
  } else {
    img.addEventListener("load", coverImage, true);
  }
};

export const AnimatedAvatar = ({ imageSrc }: { imageSrc: string }) => {
  return (
    <div>
      <img
        src={imageSrc}
        alt="Avatar"
        style={{ width: "100px", height: "100px" }}
        // onLoad={(e) => stopAnim(e.target, "user-avatar")}
      />
    </div>
  );
};

export const AnimatedIcon = ({ iconKey }: { iconKey: string }) => {
  return (
    <div>
      <img
        src={IconList[iconKey]}
        alt="Working Icon"
        style={{ width: "100px", height: "100px" }}
      />
    </div>
  );
};
