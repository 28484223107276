import axios, { AxiosInstance } from "axios";

const getBaseUrl = () => {
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://coachgpt-2utdk5kboa-rj.a.run.app";
      break;
    case "development":
    default:
      url = "https://coachgpt-2utdk5kboa-rj.a.run.app";
  }

  return url;
};

class BaseApi {
  api: AxiosInstance;
  constructor() {
    this.api = axios.create({
      baseURL: getBaseUrl(),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default BaseApi;
