import { ElementType, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthServices from "../../services/AuthServices";
import { LoggedUser } from "../../types/LoggedUser";

function ProtectedRoute({
  element: Element,
  ...rest
}: {
  element: ElementType;
}) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [loggedUser, setLoggedUser] = useState<LoggedUser | null>(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const loggedUser = localStorage.getItem("loggedUser");
        if (!loggedUser) {
          throw new Error("No user logged in");
        }
        const userData = await AuthServices.isLogged(loggedUser);
        setIsAuthenticated(true);
        setLoggedUser(userData);
      } catch (error) {
        console.error("Error during authentication", error);
        setIsAuthenticated(false);
      }
    };
    checkAuthentication();
  }, []);

  if (isAuthenticated === null) {
    return null;
  }

  return isAuthenticated ? (
    <Element {...loggedUser} />
  ) : (
    <Navigate to="/login" replace />
  );
}

export default ProtectedRoute;
