import { useNavigate } from "react-router-dom";
import { LoggedUser } from "../../types/LoggedUser";
import "./UserHome.css";

const HomeCard = (props: {
  title: string;
  subTitle: string;
  route: string;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="card container home-card"
      onClick={() => {
        navigate(props.route);
      }}
    >
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        <p className="card-text">{props.subTitle}</p>
      </div>
    </div>
  );
};

const UserHome = (user: LoggedUser) => {
  return (
    <div
      className="jumbotron"
      style={{ height: "100vh", marginBottom: "0", overflow: "auto" }}
    >
      <h1 className="display-4">Hello, {user.username}!</h1>
      <p className="lead">
        This is the Interivew Training System, here you can tailor interviews
        for the job position you are applying for.
      </p>
      <hr className="my-4" />
      <p>
        This project is still in development, so please be patient with us as we
        work to improve the system.
        {!user.has_profile ? (
          <p>
            It seems you have not completed your profile, please do so to better
            tailor your interviews
          </p>
        ) : null}
      </p>
      {user.has_profile ? (
        <div className="container-fluid home-card-container">
          <HomeCard
            {...{
              title: "New Interview",
              subTitle:
                "Creates a new interview based on the role and job description provided",
              route: "/interview",
            }}
          />
          <HomeCard
            {...{
              title: "Interview History",
              subTitle: "List of your previous interviews and their results",
              route: "/history",
            }}
          />
          <HomeCard
            {...{
              title: "Update Profile",
              subTitle:
                "Update your CV and personal information to better tailor your interviews",
              route: "/profile",
            }}
          />
        </div>
      ) : (
        <HomeCard
          {...{
            title: "Update Profile",
            subTitle:
              "Update your CV and personal information to better tailor your interviews",
            route: "/profile",
          }}
        />
      )}
    </div>
  );
};

export default UserHome;
