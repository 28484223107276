import {
  InterviewFinishPATCH,
  InterviewGET,
  InterviewHistoryGET,
  InterviewPOST,
  InterviewStartPOST,
} from "../types/InterviewService";
import BaseApi from "./HttpServices";

export class InterviewService extends BaseApi {
  token: string;

  constructor({ token }: { token: string }) {
    super();

    this.token = token;
  }
  async CreateInterview(
    jobTitle: string,
    jobDescription: string
  ): Promise<InterviewPOST> {
    try {
      const response = await this.api.post(
        "/interview/",
        {
          job_description: jobDescription,
          job_title: jobTitle,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error creating the interview", error); // TODO fix error messages
      throw error;
    }
  }

  async GetInterviewsHistory(
    page: number = 0
  ): Promise<Array<InterviewHistoryGET>> {
    try {
      const response = await this.api.get(`/interview/`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          page,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error getting interview history", error);
      throw error;
    }
  }

  async GetInterviewInfo(interviewId: number): Promise<InterviewGET> {
    try {
      const response = await this.api.get(`/interview/${interviewId}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error getting interview data", error);
      throw error;
    }
  }

  async StartInterview(interviewId: number): Promise<InterviewStartPOST> {
    try {
      const response = await this.api.post(
        `/interview/${interviewId}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching the interview data", error);
      throw error;
    }
  }

  async FinishInterview(interviewId: number): Promise<InterviewFinishPATCH> {
    try {
      const response = await this.api.patch(
        `/interview/${interviewId}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error finishing the interview", error);
      throw error;
    }
  }
}
