import "./RecordingTag.css";

export const RecordingTag = ({ recording }: { recording: boolean }) => {
  return recording ? (
    <div className="recording-container">
      <div className="recording-circle"></div>
      <div className="recording-text">Recording</div>
    </div>
  ) : (
    <div></div>
  );
};
